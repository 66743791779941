import React from "react"
import WhatLayout from "../../components/WhatLayout";
import fische2018 from "../../images/what/fun/fische-2018.png";
import hohoh from "../../images/what/fun/hohoho.png";
import pitbull from "../../images/what/fun/pitbull-halloween.png";
import schuetze from "../../images/what/fun/schuetze-2018.png";
import sickheadCat from "../../images/what/fun/sickhead-cat.png";
import tinkerbelle from "../../images/what/fun/tinkerbelle.png";
import monstermash from "../../images/what/fun/monstermash.png";

const Page = () => {

  return (
    <WhatLayout
      header={"Fun"}
      images={[
        fische2018,
        hohoh,
        pitbull,
        schuetze,
        sickheadCat,
        tinkerbelle,
        monstermash
      ]}
    />
  );

};

export default Page;
